<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="PACKAGES"
          :breadcrumb="[
            {
            label: 'Dashboard',
            },
             { label: 'Packages & Services' },
            { label: 'Packages' }
            
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"

            >
            <div class="card-body">
                <div class="row">
                  <div class="col text font">

                    <h4>
                      <b style="color: #00364f; font-weight: bolder"
                        >Package</b
                      >
                    </h4>
                  </div>
                  <div class="col text-end ">
                    <button
                      style="background-color: rgb(242 19 1);"
                      @click="openSavePackageData"
                      type="button"
                      title="Add New Package"
                      class="btn btn-sm text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#editModal"
                    >
                      <b>Add New</b>
                    </button>
                    <!-- &nbsp;<button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button> -->

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>








                <!-- start list area  -->
                <div class="row"  style="margin-top:5px">
              
                  <!-- <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-end">
                          <h4> <b style="color: #00364f;font-weight: bolder;"><button type="button" class="btn text-white btn-sm" style="background-color:#f21300;font-size:13px;letter-spacing: 1px;"  data-bs-toggle="modal" data-bs-target="#exampleModal"><b><font-awesome-icon icon="plus"/> New Package</b></button></b> </h4>
                      </div> -->

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>+</th>
                          <th style="width: 10%">Package</th>
                          <th style="width: 5%">Charge</th>
                          <th style="width: 5%">Priority</th>
                          <th style="width: 50%">Remark</th>
                          <th style="width: 10%">Servces</th>
                          <th style="width: 5%">Status</th>
                          <th>Action</th>
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(pkg, index) in packages"
                            :key="index"
                          >
                            <td>
                              <img
                                style="width: 8px; margin-right: 5px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td>

                            <td v-if="pkg">{{ pkg.package }}</td>
                            <td v-if="pkg">{{ pkg.onBoardingCharge }}</td>
                            <td v-if="pkg">
                              {{ pkg.priority }}
                            </td>
                            <td v-if="pkg">{{ pkg.remark }}</td>
                            <td class="text-center">
                              <button
                                @click="getServices(pkg)"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#servicesModal"
                                class="btn text-white btn-sm"
                                style="
                                  background-color: rgb(242 19 1);
                                  padding: 5px 7px 5px 7px;
                                "
                              >
                                <b>
                                  <font-awesome-icon icon="link" />
                                </b>
                              </button>
                            </td>
                            <td class="text-center">
                              <p
                                v-if="pkg.status == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>

                            <td class="text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  title="Edit"
                                  type="button"
                                  @click="editPackage(pkg)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editModal"
                                  class="btn btns btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>

                                <button
                                  type="button"
                                  @click="deletePackage(pkg.id)"
                                  class="btn btns btn-sm btn-danger"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="trash" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
            
              <div class="card-footer" >
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadPackages(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadPackages(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadPackages(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadPackages(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadPackages(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadPackages(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadPackages(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadPackages(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadPackages(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadPackages( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadPackages( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadPackages(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadPackages(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadPackages(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadPackages(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadPackages(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!--start model  -->
  <div
    class="modal fade"
    id="servicesModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content" >
        <div class="modal-header bg-white" >
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f;"
          >
            <b>Add Services in {{ pkgResandMethod.package }}</b>
          </h5>
          <button
          @click="getAllServices()"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row mt-1">
            <div class="col">
              <div class="container-fluid table-scroll">
                <table class="table table-hover table-sm">
                  <tr
                    class="text-white tr-head table-wrapper-scroll-y rounded-circle"
                    style="background-color: #00364f"
                  >
                    <th style="width: 10%">+</th>
                    <th style="width: 80%">Services</th>
                    <th style="width: 10%">Package</th>
                  </tr>
                  <tbody>
                    <tr v-for="(service, index) in services" :key="index">
                      <td>
                        <!-- <span v-if="service.id == otherPackageServices">
                            hi
                        </span>
                         <span v-else>
                           by
                        </span> -->

                        <!-- <span
                          v-if="
                            findDisabeldServicesAnotherPackage(service.id, package_id) ==
                            true
                          "
                        >
                          <input
                            style="background: gray"
                            class="form-check-input"
                            type="checkbox"
                            disabled
                          />
                        </span> -->
                        <!-- services -->
                        <span v-if="service.servicePackages">
                          <input
                          v-if="service.servicePackages.service_id == service.id"
                            @click="addNewServiceInPackage(service, $event,index)"
                            class="form-check-input"
                            type="checkbox"
                           
                        checked
                          />
                          <input
                          v-else
                            @click="addNewServiceInPackage(service, $event,index)"
                            class="form-check-input"
                            type="checkbox"
                           
                        
                          />
                        </span>
                        <span v-else>
                          <input
                            @click="addNewServiceInPackage(service, $event,index)"
                            class="form-check-input"
                            type="checkbox"
                       
                        
                          />
                        </span>

                        <!-- <input @click="addNewServiceInPackage(service, $event)"  -->
                        <!-- :value="service.id" v-model="pkgServices" /> -->
                      </td>
                      <td>
                        <label
                          for="flexCheckDefault"
                          style="color: #00364f; font-weight: 600"
                          v-if="service"
                        >
                          {{ service.name }}</label
                        >
                      </td>
                      <td class="text-center">



                        <span v-if="service.package">
                     <small   style="color: #00364f; font-weight: 600"> {{ service.package }} </small>  

                          <!-- <small
                            v-if="service.package == 'Basic'"
                            class="text-center"
                            style="
                              font-size: 10px;
                              background-color: black;
                              color: white;
                              width: 85%;
                              border-radius: 15px;
                              padding: 3px 8px 3px 8px;

                              box-shadow: 0px 0px 5px gray;
                            "
                            >{{ service.package }}
                          </small>
                          <small
                            v-if="service.package == 'Pro'"
                            class="text-center"
                            style="
                              font-size: 10px;
                              background-color: #f21000;
                              color: white;
                              width: 80%;
                              border-radius: 15px;
                              padding: 3px 8px 3px 8px;
                              box-shadow: 0px 0px 5px gray;
                            "
                            >{{ service.package }}
                          </small>
                          <small
                            v-if="service.package == 'Advance'"
                            class="text-center bg-primary"
                            style="
                              font-size: 10px;
                              color: white;
                              width: 100%;
                              border-radius: 15px;
                              padding: 3px 8px 3px 8px;

                              box-shadow: 0px 0px 5px gray;
                            "
                            >{{ service.package }}
                          </small> -->
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-1">
            <button
            @click="getAllServices()"
              type="button"
              class="btn text-white btn-sm"
              style="background-color: #f21300"
              data-bs-dismiss="modal"
            >
              <b>Cancel</b>
            </button>
            <!-- <button type="submit" class="btn text-white" style="background-color:#00364f;"><b>Confirm</b></button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->

  <!-- for edit  -->

  <!--start model  -->
  <div
    class="modal bs fade"
    id="editModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content" >
        <div class="modal-header bg-white" >
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; "
          >
            <b>{{ edit == "false" ? "Add" : "Edit" }} Package</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="edit == 'false' ? savePackageData() : updatePackage()">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-12" v-if="form.errors">
                <div v-if="form.errors.message">
                  <div class="alert alert-danger" role="alert">
                    {{ form.errors.message }}
                  </div>
                </div>
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label>Package Name <span style="color: #f21000">*</span> </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Package Name"
                    v-model="form.package"
                    required
                    style="height: 30px"
                    @change="lenthheker($event, 'typeName')"
                  />
                </div>
                <span style="color: #f21000" id="typeName"></span>
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group mt-1">
                  <label>On Boarding Charge <span style="color: #f21000">*</span></label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="On Boarding Charge"
                    v-model="form.onBoardingCharge"
                    required
                    style="height: 30px"
                  />
                </div>
              </div>

              <div
                class="col-xl-6 col-lg-6 col-md-6 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label>Priority <span style="color: #f21000">*</span></label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Priority"
                    v-model="form.priority"
                    required
                    style="height: 30px"
                  />
                </div>
              </div>

              <div
                class="col-xl-6 col-lg-6 col-md-6 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group">
                  <label>Gst Required</label>
                  <select
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="form.gstRequired"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option selected value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>

              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group mt-1">
                  <label>Status</label>
                  <select
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    class="form-select"
                    aria-label="Default select example"
                    v-model="form.status"
                  >
                    <option selected value="true">Active</option>
                    <option value="false">In Active</option>
                  </select>
                </div>
              </div>

              <div
                class="col-xl-12 col-lg-12 col-md-12 col-12"
                style="padding: 3px 3px 3px 3px"
              >
                <div class="form-group mt-1">
                  <label>Remark</label>
                  <textarea
                    class="form-control"
                    rows="4"
                    placeholder="Remark"
                    v-model="form.remark"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="form-group mt-1 text-end">
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->
</template>

//
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
var _ = require("lodash");
export default {
  name: "superAdminPackage",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      loading: false,
      pkgResandMethod: {},
      index: "",
      edit: "false",
      packages: [],
      services: [],
      packageservice: [],
      packageService_Form: {
        package_id: null,
        service_id: null,
        remark: "",
      },
      form: {
        id: null,
        package: "",
        gstRequired: "true",
        status: "true",
        priority: "",
        onBoardingCharge: "",
        remark: "",
        errors: {},
      },
      pkgServices: [],
      package_id: null,

      otherPackageServices: [],
      submitStatus: "false",
    };
  },
  methods: {
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadPackages();
    },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "typeName") {
        if (data.length < 3) {
          this.submitStatus = "true";
          $("#typeName").html("<small>Package Name  required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.submitStatus = "false";
          $("#typeName").html("<small></small>");
        }
      }
    },
    addNewServiceInPackage(serviceValues, event,index) {

    
      this.packageService_Form.service_id = serviceValues.id;
      if (event.target.checked == true) {
        this.loading = true;
        this.$axios
          .post(
            `superadmin/packageservice?package_id=${this.package_id}`,
            this.packageService_Form,
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            res.data;
            this.$swal;

            toast.success("Service Add Succesfully", {
              autoClose: 1000,
            });
            // this.getAllServices();
            // this.getAllPackageServices();
            // this.getServices(this.pkgResandMethod);
            this.getServices(this.pkgResandMethod);
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.$swal.fire({
              confirmButtonColor: "#00364f",
              icon: "error",
              title: "Oops...",
              text: error.response.data.error.service_id,
            });
          });
        // this.getServices(this.pkgResandMethod);
      } else {
        // alert('cant factch api')
        for (let i = 0; i < this.packageservice.length; i++) {
          if (this.packageservice[i].service_id == this.packageService_Form.service_id) {
            // alert('delete')
            this.loading = true;
            this.$axios
              .delete(`superadmin/packageservice/${this.packageservice[i].id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;
                toast.success("Package Service Deleted Succesfully ", {
                  autoClose: 1000,
                });
                console.log(serviceValues)
if(serviceValues.package){
  this.services[index].package = ""
}
                this.getServices(this.pkgResandMethod);
              }).finally(() => (this.loading = false))
              .catch((error) => {
                console.log(error.response.data)
            this.$swal.fire({
              confirmButtonColor: "#00364f",
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          })    
            
          }
        }
      }



      // this.getAllServices();
      //           this.getAllPackageServices();
                this.getServices(this.pkgResandMethod);
    },

    getAllPackageServices() {
      this.$axios
        .get(`superadmin/packageservice?all=all`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.otherPackageServices = res.data.data;

          var service = this.services;
          _.forEach(this.otherPackageServices, function (value) {
            if (
              _.find(service, function (o) {
                return o.id == value.service_id;
              })
            ) {
              var index = _.findIndex(service, function (o) {
                return o.id == value.service_id;
              });
              service[index].package = value.package.package;
            }
          });
        });
    },
    findDisabeldServicesAnotherPackage(serviceId, pkg_id) {
      serviceId;
      pkg_id;

      var pkg = this.services.find((p) => p.id == serviceId);

      if (pkg) {
        return false;
      } else {
        return true;
      }
    },
    getAllServices() {
      
console.log('------------------------------------------') 
console.log('--------hiiihihhi----------------Bachhhe------------------')
      this.$axios
        .get("superadmin/service?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.services = res.data.data;
            this.getAllPackageServices();
        });
    },
    getServices(pkg) {
      this.pkgResandMethod = pkg;
      this.packageService_Form.package_id = pkg.id;
      this.package_id = pkg.id;
   
      this.getAllPackageServices();

      this.$axios
        .get(`superadmin/packageservice?package_id=${pkg.id}&all=all`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.packageservice = res.data.data;
          console.log(this.packageservice)
          console.log(this.services)
        // ---------------------------------------------------
          var serModule = this.services;
          _.forEach(this.packageservice, function (value) {
            if (
              _.find(serModule, function (o) {
                return o.id == value.service_id;
              })
            ) {
              var index = _.findIndex(serModule, function (o) {
                return o.id == value.service_id;
              });
           
              serModule[index].servicePackages = value;
              
            }
          });
          this.services = serModule;



        });

      // this.findDisabeldServicesAnotherPackage(this.package_id);

      // .finally(() => (this.loading = false))

    },

    editPackage(pkg) {
      this.form = {};
      this.form.errors = {};
      this.edit = "true";
      this.form = pkg;

      this.submitStatus = "false";
          $("#typeName").html("<small></small>");
    },
    updatePackage() {
      // this.loading = true;
      if (this.submitStatus == "false") {
        this.$axios
          .put(`superadmin/package/${this.form.id}`, this.form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;

            toast.success("Package Updated  Succesfully", {
              autoClose: 1000,
            });
            this.loadPackages();
            this.form = {};
            this.form.errors = {};
            $("#editModal").modal("hide");
          })
          .catch((error) => {
            this.form.errors = error.response.data;

            var msg =""
if(error.response.data.error.priority[0]){
msg = error.response.data.error.priority[0]
}
else{
  msg = error.response.data.error
}

this.$swal.fire({
      position: "top-center",
      icon: "error",
      title: "<small>" +msg + "</small>",
      showConfirmButton: true,
      confirmButtonColor: "#00364f",
    });



  });

      }
    },

    deletePackage(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`superadmin/package/${id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;
                toast.success("Package Deleted  Succesfully", {
                  autoClose: 1000,
                });
                this.loadPackages();
              })
              .catch((error) => {
                console.log(error.response.data)
            this.$swal.fire({
              confirmButtonColor: "#00364f",
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });
          }
        });
    },
    openSavePackageData() {
      this.edit = "false";
      this.form = {};
      this.form.errors = {};

      this.form.status = "true";
      this.form.gstRequired = "true";
      

      this.submitStatus = "false";
          $("#typeName").html("<small></small>");
    },
    savePackageData() {
      // this.loading = true;
      if (this.submitStatus == "false") {
        this.$axios
          .post("superadmin/package", this.form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res.data;

            toast.success("New Package Created", {
              autoClose: 1000,
            });
            this.loadPackages();
            this.form = {};
            this.form.errors = {};
            $("#editModal").modal("hide");
          })
          .catch((error) => {
            this.form.errors = error.response.data;

            var msg =""
if(error.response.data.error.priority[0]){
msg = error.response.data.error.priority[0]
}
else{
  msg = error.response.data.error
}

this.$swal.fire({
      position: "top-center",
      icon: "error",
      title: "<small>" +msg + "</small>",
      showConfirmButton: true,
      confirmButtonColor: "#00364f",
    });



  });

      }
    },
    loadPackages(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        pageUrl += `superadmin/package?per_page=${this.per_page}`;
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.packages = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.package
    console.log(this.$store.state.superAdminPageTitles.package)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadPackages();
    
      // this.getServices();
          this.getAllServices()
          this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}


.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
